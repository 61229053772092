<template>
  <navbar :class="{ show: true }" :showLinks="false"></navbar>
  <vue-countdown ref="timer" :time="this.prebooking.data.ttl * 1000" @end="onCountdownEnd" :transform="transformSlotProps" v-slot="{minutes, seconds}">
    <div class="timer-container">
      <p>{{ $t('timeLeftBooking') }}</p>
      <p :class="[this.minutes > 0 ? 'timer-time': 'timer-limit']">{{minutes}}:{{seconds}}</p>
    </div>
  </vue-countdown>
  <spinner :visible="spinnerVisible"></spinner>
  <div class="container-detail">
    <section class="hotel-detail-header">
      <section class="hotel-info">
        <detail-header :hotel="hotel_data" :btnActive="false"></detail-header>
      </section>
    </section>
    <section>
      <purchase-card
          :bookToken="prebooking.data.hotel.roomRates[0].bookToken"
          :rooms="prebooking.data.hotel.roomRates[0].rooms"
          :nights="calculateDays()"
          :checkIn="prebooking.data.hotel.checkIn"
          :checkOut="prebooking.data.hotel.checkOut"
          :price="prebooking.data.hotel.roomRates[0].pricing.sell.price"
          :cancellationPolicies="prebooking.data.hotel.roomRates[0].cancellationPolicies"
          :meal="prebooking.data.hotel.roomRates[0].meal.name"
          :rateId="prebooking.data.hotel.roomRates[0].rateID"
          :plan="0"
          :btnActive="false"
      >
      </purchase-card>
      <div class="book-info">
        <div>
          <h3 class="info-title">{{ $t('bookingInformation') }}</h3>
          <div class="row">
            <p class="sub-title">{{ $t('reservationDates') }}</p>
            <p>{{ $tc('from', 2) }} {{ getDayOfWeek(prebooking.data.hotel.checkIn, site.language) }} {{ this.formatDate(prebooking.data.hotel.checkIn) }}
              {{ $t('to') }} {{ getDayOfWeek(prebooking.data.hotel.checkOut, site.language) }} {{ this.formatDate(prebooking.data.hotel.checkOut) }}.
              <a @click="returnToPrebook()" class="lnkChangeDates">{{ $t('changeDates') }}</a>
            </p>
          </div>
        </div>
        <div v-if="prebooking.data.congress_observations">
          <h3 class="info-title">{{ $t('congressObservations') }}</h3>
          <p class="observations-text">{{prebooking.data.congress_observations}}</p>
        </div>
      </div>
    </section>

    <form class="passengers-form">
      <ContactInputs
          @blurInput='onBlur'
          ref='formContact'
          :language="site.language.toLowerCase()"
      />
      <BillingInputs
          @blurInput='onBlur'
          ref='formBilling'
          :language="site.language.toLowerCase()"
      />

      <div v-for="(room, index) in this.prebooking.data.hotel.roomRates[0].rooms" :key="index">
        <h2 class="title-form">{{ $t('roomLabel') }} {{ index + 1 }}: {{ room.name }} </h2>

        <section class="contWell">
          <PassengerInputs
              v-for="n in passengersForRoom(room)" :key="n"
              @blurInput='onBlur'
              :id="index + '_' + (n-1)"
              ref="passengerInput"
          />
        </section>
      </div>
          <ClientObservations
          @blurInput='onBlur'
          ref='formObservations'
      />
      <div class="privacy">
        <p class="privacy-title">{{ $t('privacyPolicy').toUpperCase() }}</p>
        <p class="privacy-text">
          {{ $t('beforeSend') }}
          <a :href="privacy_policy_url" target="_blank" rel="noopener noreferrer">{{ $t('dataProtection') }}</a>.
        </p>
        <p class="privacy-text">{{ $t('continuingProcess') }}</p>
      </div>
      <button @click="payment" class="btn btn-primary btnPayment">{{ $t('payment') }}</button>
    </form>
  </div>

  <contact-footer :showFooter="true"></contact-footer>
  <legal-footer :showFooter="true"></legal-footer>

</template>

<script>
import Constants from '@/constants'
import {mapGetters} from "vuex";
import bookingService from "@/services/bookingService";
import ContactInputs from "@/components/ContactInputs";
import BillingInputs from "@/components/BillingInputs";
import ClientObservations from "../components/ClientObservations";
import PassengerInputs from "@/components/PassengerInputs";
import Navbar from "@/components/Navbar";
import ContactFooter from "@/components/landing-page/ContactFooter";
import LegalFooter from "@/components/landing-page/LegalFooter";
import DetailHeader from "@/components/hotel-detail-page/DetailHeader";
import PurchaseCard from '@/components/booking/PurchaseCard';
import Spinner from "@/components/Spinner";
import VueCountdown from '@chenfengyuan/vue-countdown';
import { formatDate, getDayOfWeek } from '@/helpers/utils'

export default {
  name: "Booking",
  components: {
    Spinner,
    ContactInputs,
    BillingInputs,
    ClientObservations,
    PassengerInputs,
    Navbar,
    ContactFooter,
    LegalFooter,
    DetailHeader,
    PurchaseCard,
    VueCountdown,
  },
  computed: {
    ...mapGetters(["hotels", "prebooking", "dataIsReady", "dataHotelsIsReady", "site",]),
    hotel_data() {
      if (this.prebooking.hotel_id) {
        if (this.dataHotelsIsReady) {
          return this.$store.getters.hotel(Number(this.prebooking.hotel_id));
        } else if (this.dataIsReady) {
          return this.$store.getters.staticHotel(Number(this.prebooking.hotel_id));
        }
      }
      return {}
    },
  },
  data() {
    return {
      payment_data: {
        contact: {},
        billing: {},
        dataPassengers: [],
        customer_observations:'',
      },
      mapped_payment_data: {},  // data mapped to send
      hote_id: null,
      formPassengersIsReady: false,
      spinnerVisible: false,
      privacy_policy_url: Constants.PRIVACY_POLICY_URL,
    }
  },
  methods: {
    formatDate: formatDate,
    getDayOfWeek: getDayOfWeek,
    transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },
    onCountdownEnd: function () {
      this.returnToPrebook()
    },
    async payment(e) {
      e.preventDefault();
      this.spinnerVisible = true;

      const billing_validated = await this.$refs.formBilling.checkValidate()
      const contact_validated = await this.$refs.formContact.checkValidate()

      // Check general errors
      if (!(billing_validated && contact_validated)) {
        this.spinnerVisible = false;
        this.$notify({
          type: "error",
          title: "Error",
          text: this.$i18n.t('errorsForm')
        });
        return
      }

      //Check phone if Spain
      if (this.payment_data.contact.phone_prefix === Constants.SPAIN_PREFIX) {
        if (!this.isValidSpainPhone(this.payment_data.contact.phone)) {
          this.spinnerVisible = false;
          this.$notify({
            type: "error",
            title: "Error",
            text: this.$i18n.t('validPhoneSpain')
          });
          return
        }
      }

      const countryIsSpain = this.payment_data.billing.billing_country.iso == Constants.SPAIN_ISO_COUNTRY
      if (countryIsSpain) {
        const isValidDniNieCif = this.isValidDniNie(this.payment_data.billing.billing_tax_number) ||
            this.isValidCif(this.payment_data.billing.billing_tax_number)
        if (!isValidDniNieCif) {
          this.spinnerVisible = false;
          this.$notify({
            type: "error",
            title: "Error",
            text: this.$i18n.t('validSpainId')
          });
          return
        }
      }

      this.mapped_payment_data['dataPassengers'] = this.payment_data.dataPassengers.map((p) => {
        let data = Object.values(p)[0]
        return {
          'index': data.index,
          'name': data.person.name,
          'lastname': data.person.lastname
        }
      });

      // JSON.parse and JSON.stringify to deep copy of an object
      this.mapped_payment_data['billing'] = JSON.parse(JSON.stringify(this.payment_data.billing));
      this.mapped_payment_data['contact'] = JSON.parse(JSON.stringify(this.payment_data.contact));
      this.mapped_payment_data['customer_observations'] = this.payment_data.customer_observations
       // TODO review separa en componente
      this.mapped_payment_data['billing']['billing_country_iso'] = this.payment_data.billing.billing_country.iso
      this.mapped_payment_data['billing']['billing_country'] = this.payment_data.billing.billing_country.name
      this.mapped_payment_data['contact']['country_iso'] = this.payment_data.contact.country.iso
      this.mapped_payment_data['contact']['country'] = this.payment_data.contact.country.name

      bookingService.payment(
          this.prebooking.data.reference,
          this.prebooking.data.hotel.roomRates[0].bookToken,
          this.$refs.timer.totalSeconds,
          this.mapped_payment_data,
      )
          .then(data => {
            if (typeof data.payment_url === "string") {
              window.location.href = data.payment_url
            }
            else {
              this.$notify({
                type: "error",
                title: "Error",
                text: this.$i18n.t('paymentPlarformNotWorking')
              });
            }
          })
          .catch((_err) => {
            this.$notify({
              type: "error",
              title: "Error",
              text: this.$i18n.t('paymentPlarformNotAvailable')
            });
          })
          .finally(() => this.spinnerVisible = false)
    },
    createFormPassengers() {
      for (let i = 0; i < this.prebooking.distribution.length; i++) {
        for (let j = 0; j < this.prebooking.distribution[i].passengerAges.length; j++) {
          this.payment_data.dataPassengers.push({
            [i + "_" + j]: {
              "index": this.prebooking.distribution[i].index,
              "age": Constants.PASSENGER_AGE,
              "person": {
                "name": "",
                "lastname": ""
              }
            }
          })
          this.formPassengersIsReady = true;
        }
      }
    },
    passengersForRoom(room) {
      let r = this.prebooking.distribution.find(obj => obj.index === room.index)
      return Object.keys(r.passengerAges).length
    },
    updateFirstPassenger(ev) {
      if (ev.key == 'contact') {
        if(!this.payment_data.dataPassengers[0]['0_0'].person.name && this.payment_data.contact.name){
          this.$refs.passengerInput[0].updatePassengerName(this.payment_data.contact.name)
          this.payment_data.dataPassengers[0]['0_0'].person.name = this.payment_data.contact.name
        }

        if(!this.payment_data.dataPassengers[0]['0_0'].person.lastname && this.payment_data.contact.lastname){
          this.$refs.passengerInput[0].updatePassengerLastName(this.payment_data.contact.lastname)
          this.payment_data.dataPassengers[0]['0_0'].person.lastname = this.payment_data.contact.lastname
        }
      }

    },
    onBlur(ev) {
      this.updateFirstPassenger(ev)

      if (ev.key == 'dataPassengers') {
        const objIndex = this.payment_data[ev.key].findIndex(obj => Object.keys(obj)[0] == ev.id)
        if (objIndex >= 0) {
          this.payment_data[ev.key][objIndex][ev.id]['person'] = ev.data
        }
      } else{
        this.payment_data[ev.key] = ev.data
      }
    },
    calculateDays() {
      let checkIn = new Date(this.prebooking.data.hotel.checkIn);
      let checkOut = new Date(this.prebooking.data.hotel.checkOut);
      let diffTime = Math.abs(checkOut - checkIn);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
    isValidSpainPhone(phone){
      return phone.toString().length === 9 && /^[6789]{1}[0-9]{8}$/.test(phone.toString())
    },
    isValidDniNie(dni) {
      let num, dni_letter, letter
      let regex_dni = /^[XYZ]?\d{5,8}[A-Z]$/

      dni = dni.toUpperCase().trim()

      if(regex_dni.test(dni) == true){
        num = dni.substr(0,dni.length-1)
        num = num.replace('X', 0)
        num = num.replace('Y', 1)
        num = num.replace('Z', 2)
        dni_letter = dni.substr(dni.length-1, 1)
        num = num % 23
        letter = 'TRWAGMYFPDXBNJZSQVHLCKET'
        letter = letter.substring(num, num+1)
        return letter == dni_letter
      }else{
        return false
      }
    },
    matchLetterAZ(letter) {
      return letter.match(/[A-Z]/)
    },
    matchLetterAH(letter, digit, control) {
      if (letter.match(/[ABEH]/)) {
        return String(digit) === control
      }
      return false
    },
    matchLetterNW(letters, letter, digit, control) {
      if (letter.match(/[NPQRSW]/)) {
        return letters[digit] === control
      }
      return false
    },
    isValidCif(cif) {
      if (!cif || cif.length !== 9) {
        return false
      }
      let result = false
      let letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
      let digits = cif.substr(1, cif.length - 2)
      let letter = cif.substr(0, 1)
      let control = cif.substr(cif.length - 1)
      let sum = 0
      let i
      let digit

      result = this.matchLetterAZ(letter)

      for (i = 0; i < digits.length; ++i) {
        digit = parseInt(digits[i])
        if (isNaN(digit)) {
          return false
        }
        if (i % 2 === 0) {
          digit *= 2
          if (digit > 9) {
            digit = parseInt(digit / 10) + (digit % 10)
          }
          sum += digit
        } else {
          sum += digit
        }
      }

      sum %= 10
      if (sum !== 0) {
        digit = 10 - sum
      } else {
        digit = sum
      }

      result = this.matchLetterAH(letter, digit, control) || this.matchLetterNW(letters, letter, digit, control) || String(digit) === control || letters[digit] === control

      return result
    },
    returnToPrebook() {
      this.$router.push('/availability/' + this.$route.params.id)
    },
  },
  mounted() {
    this.hote_id = this.prebooking.hotel_id
    if (!this.prebooking.data?.hotel.roomRates[0].bookToken) {
      this.returnToPrebook()
    }  
    if (Number(this.prebooking.data.hotel.roomRates[0].pricing.sell.price) !== Number(this.prebooking.prebook_price)) {
      this.$notify({
        type: "warn",
        title: this.$i18n.t('advertisement'),
        text: this.$i18n.t('priceChanged')
      });
    }
    this.createFormPassengers()
  }
}
</script>

<style lang="scss" scoped>
.hotel-info {
  margin-top: 0 !important;
}

.book-info {
  padding: 0 20px 10px 20px;
  margin: 0;
  border: 1px solid #E9E9E9;
  border-radius: 5px;

  .observations-text {
    margin-bottom: 10px;
    text-align: justify;
  }
}

.info-title {
    text-align: justify;
  }

.sub-title {
  margin-right: 10px;
}
.row {
  display: flex;
  justify-content: justify;
  align-items: center;
}
.container-detail {
  margin-top: 120px !important;
  max-width: 1200px;
}

.passengers-form {
  padding: 40px 0;
}

.lnkChangeDates {
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: var(--hover-color);
  }
}

.btnPayment {
  line-height: 40px;
  height: 40px;
  color: white;
  display: inline-block;
  background-color: var(--primary-color);

  &:hover {
    background-color: var(--hover-color);
  }
}

.title-form {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 34px;
  margin: 0 20px 20px 20px;
  color: black;
}

.contWell {
  width: 50%;
  max-width: 1170px;
  margin: 0 auto 20px;
  padding: 15px;
  background: lighten(grey, 42%);
  overflow: hidden;

  .error {
    color: darken(red, 20%);
    text-align: left;
  }
}

.clearboth {
  display: block;
  width: 100%;
  clear: both;
}

.form-control {
  float: left;
  display: block;
  width: 48%;
  margin: 1%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: $primary-black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid gray;
  border-radius: .25rem;
}

.privacy {
  margin-bottom: 20px;
  color: #6f6e6e;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 8pt;
}

.privacy-title {
    font-weight: bold;
    font-family: "Frank Ruhl Libre", serif;
  }

.privacy-text {
  font-weight: normal;

  a {
  text-decoration: none;
  color: #6f6e6e;
  }

  a:hover {
    text-decoration: underline;
  }
}

.timer-container {
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 99999;
    font-weight: bold;
    color: var(--primary-color);
    background-color: #f3f3f3;
}
.timer-time{
  color: var(--primary-color);
  font-size: 22px;
}
.timer-limit{
  color: red;
  font-size: 22px;
}

@media (max-width: 768px) {
  .contWell {
    width: 100%;

    .form-control {
      width: 100%;
      float: none;
      margin: 0;
    }
  }
}

</style>