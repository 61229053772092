<template>

    <h2 class="title-form">{{ $t('customerObservations') }}</h2>
    <p class="break-text">
      {{ $t('customerObservationsText') }}    
    </p>
    <section class="contWell">
      <div class="cont-field">
        <textarea class="form-control"
          id='customer_observations'
          :placeholder="$t('customerObservations')"
          v-model='customer_observations'
          @blur='onBlur'
        />

      </div>


    </section>

</template>

<script>

import useVuelidate from '@vuelidate/core'


export default {
  data() {
    return {
      v$: useVuelidate(),
      customer_observations: '',
    }
  },
  props: ['label'],
  methods: {
    async onBlur() {
      this.$emit('blurInput', {data: this.customer_observations, key: 'customer_observations'})
    },
  },

}
</script>

<style lang="scss" scoped>
.title-form {
  font-family:"Frank Ruhl Libre", serif;
  font-size:34px;
  margin: 0 20px 20px 20px;
  color: black;
}
.contWell {
  width:50%;
  max-width:1170px;
  margin:0 auto 20px;
  padding:15px;
  background:lighten(grey,42%);
  overflow:hidden;
  .error {
    color:darken(red,20%);
    text-align:left;
    font-size:12px;;
  }
}
.break-text {
  max-width:1000px;
  text-align:center;
  margin:0 auto 10px;
  font-size: 12px;
}
.clearboth {
  display:block;
  width:100%;
  clear:both;
}
.cont-field {
  height:120px;
  width: 98%;
  margin:1%;
  float:left;
  .form-control {
    width:100%;
  }
}
.form-control {
  float:left;
  display: block;
  height: 120px;
  padding: .375rem .75rem;
  margin:0 0 4px 0;
  font-size: 1rem;
  line-height: 1.5;
  color: $primary-black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid gray;
  border-radius: .25rem;
}
 textarea, textarea::placeholder {
  font-family: "Source Sans Pro", sans-serif;
}
@media (max-width: 768px) {
  .contWell {
    width:100%;
    .cont-field {
      width:100%;
      margin:1% 0 1% 0;
    }
    .form-control {
      width:100%;
      float:none;
      margin:0;
    }
  }
}

</style>