<template>
  <select v-model="selected" class="form-control">
    <option :value="null" disabled>{{label}}</option>
    <option v-for="country in countries" :key="country.value" v-bind:value="{name: country.label, iso: country.value}">
      {{ country.label }}
    </option>
  </select>
</template>
<script>
const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
countries.registerLocale(require('i18n-iso-countries/langs/es.json'))
export default {
  name: 'CountrySelection',
  props: {
    label: String,
    language: String,
  },
  computed: {
    countries () {
      let list = countries.getNames(this.language, { select: 'official' })
      let orderedList = Object.fromEntries(Object.entries(list).map(a => a.reverse()).sort())
      return Object.keys(orderedList).map((key) => ({ value: orderedList[key], label: key }))
    }
  },
  data () {
    return {
      selected: null
    }
  }
}
</script>

<style lang="scss" scoped>
.form-control {
  float:left;
  display: block;
  width: 48%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: $primary-black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid gray;
  border-radius: .25rem;
}
</style>