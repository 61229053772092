<template>
    <input type='text' class="form-control"
      id='name' 
      :placeholder="$t('name')"
      v-model='passenger.name'
      @blur='onBlur' 
    />
    <input type='text' class="form-control"
      id='lastname' 
      :placeholder="$t('lastName')"
      v-model='passenger.lastname'
      @blur='onBlur' 
    />
</template>

<script>

export default {
  props: ['id'],
  data() {
    return {
      passenger: {
        name: '',
        lastname: '',
      },
    }
  },
  methods: {
    onBlur() {
      this.$emit('blurInput', {id: this.id, data: this.passenger, key: 'dataPassengers'})
    },
    updatePassengerName(name){
      this.passenger.name = name
    },
    updatePassengerLastName(lastname){
      this.passenger.lastname = lastname
    }
  },
}
</script>

<style lang="scss" scoped>
.form-control {
  float:left;
  display: block;
  width: 48%;
  margin:1%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: $primary-black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid gray;
  border-radius: .25rem;
}

@media (max-width: 768px) {
  .contWell {
    width:100%;
    .form-control {
      width:100%;
      float:none;
      margin:1% 0 1% 0;
    }
  }
}

</style>