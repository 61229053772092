<template>
  <div :class="['vue-tel-input', styleClasses, { disabled: disabled }]">
    <div
      v-click-outside="clickedOutside"
      aria-label="Country Code Selector"
      aria-haspopup="listbox"
      :aria-expanded="open"
      role="button"
      :class="['vti__dropdown', { open: open, disabled: dropdownOptions.disabled }]"
      :tabindex="dropdownOptions.tabindex"
      @keydown="keyboardNav"
      @click="toggleDropdown"

      @keydown.esc="reset"
      @keydown.tab="reset"
    >
      <span class="vti__selection">
        <span
          v-if="dropdownOptions.showFlags"
          :class="['vti__flag', activeCountryCode.toLowerCase()]"
        ></span>
        <span v-if="dropdownOptions.showDialCodeInSelection" class="vti__country-code">
          +{{ activeCountry && activeCountry.dialCode }}
        </span>
        <slot name="arrow-icon" :open="open">
          <span class="vti__dropdown-arrow">{{ open ? "▲" : "▼" }}</span>
        </slot>
      </span>
      <ul
        v-if="open"
        ref="list"
        class="vti__dropdown-list"
        :class="dropdownOpenDirection"
        role="listbox"
      >
        <input
          v-if="dropdownOptions.showSearchBox"
          class="vti__input vti__search_box"
          aria-label="Search by country name or country code"
          :placeholder="$t('countryPrefix')"
          type="text"
          v-model="searchQuery"
          @click.stop
        />

        <li
          v-for="(pb, index) in sortedCountries"
          role="option"
          :class="['vti__dropdown-item', getItemClass(index, pb.iso2)]"
          :key="pb.iso2 + (pb.preferred ? '-preferred' : '')"
          tabindex="-1"
          @click="choose(pb)"
          @mousemove="selectedIndex = index"
          :aria-selected="activeCountryCode === pb.iso2 && !pb.preferred"
        >
          <span
            v-if="dropdownOptions.showFlags"
            :class="['vti__flag', pb.iso2.toLowerCase()]"
          ></span>
          <strong>{{ pb.name }}</strong>
          <span v-if="dropdownOptions.showDialCodeInList"> +{{ pb.dialCode }} </span>
        </li>
      </ul>
    </div>
    <input
      v-model="phone"
      ref="input"
      :type="inputOptions.type"
      :autocomplete="inputOptions.autocomplete"
      :autofocus="inputOptions.autofocus"
      :class="['vti__input', inputOptions.styleClasses]"
      :disabled="disabled"
      :id="inputOptions.id"
      :maxlength="inputOptions.maxlength"
      :name="inputOptions.name"
      :placeholder="$t('phoneNumber')"
      :readonly="inputOptions.readonly"
      :required="inputOptions.required"
      :tabindex="inputOptions.tabindex"
      :value="modelValue"
      :aria-describedby="inputOptions['aria-describedby']"
      @blur="onBlur"
      @focus="onFocus"
      @on-input="onInput"
      @keyup.enter="onEnter"
      @keyup.space="onSpace"
    />
    <slot name="icon-right" />
  </div>
</template>
<script>
import { VueTelInput } from 'vue-tel-input/dist/vue-tel-input.es';
import 'vue-tel-input/dist/vue-tel-input.css';
import utils, { getCountry, setCaretPosition } from '../utils';


function getDefault(key) {
  const value = utils.options[key];
  if (typeof value === 'undefined') {
    return utils.options[key];
  }
  return value;
}
export default {
  extends:VueTelInput,
  props: {
    dropdownOptions: {
      type: Object,
      default: () => getDefault('dropdownOptions'),
    },
    inputOptions: {
      type: Object,
      default: () => getDefault('inputOptions'),
    },
    mode: {
      type: Object,
      default: () => getDefault('mode'),
    },
    autoDefaultCountry: {
      type: Boolean,
      default: () => getDefault('autoDefaultCountry'),
    },
    defaultCountry: {
      type: [String, Number],
      default: () => getDefault('defaultCountry'),
    },
  },


  data() {
    return {
      searchQuery: '',
      phone: '',
      activeCountryCode: '',
      open: false,
      finishMounted: false,
      selectedIndex: null,
      typeToFindInput: '',
      typeToFindTimer: null,
      dropdownOpenDirection: 'below',
      parsedPlaceholder: this.inputOptions.placeholder,
    };
  },

  computed: {

    sortedCountries() {
      // Sort the list countries: from preferred countries to all countries
      const preferredCountries = this.getCountries(this.preferredCountries)
          .map((country) => ({...country, preferred: true}));
      const countriesList = [...preferredCountries, ...this.filteredCountries];
      if (!this.dropdownOptions.showSearchBox) {
        return countriesList;
      }
      return countriesList.filter(
          (c) => (new RegExp(this.searchQuery, 'i')).test(c.name)
              || (new RegExp(this.searchQuery, 'i')).test(c.iso2)
              || (new RegExp(this.searchQuery, 'i')).test(c.dialCode),
      );
    },
  },

  methods: {
    onBlur() {
      this.$emit('blurInput', this.phoneObject)
    },
    syncCountry(iso) {
      this.choose(iso)
    },
  }
}
</script>

<style lang="scss" scoped>
.form-control {
  float:left;
  display: block;
  width: 100%;
  margin:1%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: $primary-black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid gray;
  border-radius: .25rem;
}


.vue-tel-input {
  float:left;
  display: flex;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: $primary-black;
  background-color: #fff;
  background-clip: padding-box;
  text-align: left;
  border: 1px solid gray;
  border-radius: .25rem;
  margin-left: 0.1px;
  box-shadow: darken(red,20%);
}

.vti__input {
  align-items: flex-start;
  border: none;
  border-radius: .25rem;
  width: 100%;
  font-size: 1rem;
}

.vti__dropdown {
  display: block;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  position: sticky;
  top: 20px;
  padding: 0.5px;
  cursor: pointer;
  text-align: left;

}

.vue-tel-input:focus-within {
  box-shadow: inset 1.5px 1.5px 1px rgba(229, 35, 0, 1), 1.5px 1.5px 1px rgba(229, 35, 0, 1);
  border-color: darken(red,20%);
}
</style>