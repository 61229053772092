<template>
  <p class="error" v-if="errors.length">
    <span v-for="(error, index) in errors" :key="index">
    <p>{{printError(error.$params.type)}}</p>
    </span>
  </p>
</template>

<script>
import Constants from '@/constants'

export default {
  props: {
    errors: Array,
  },
  data: function () {
    return {
      
    }
  },
  methods: {
    printError(errorType) {
      let errorMessage
      switch(errorType) {
        case Constants.FORM_ERRORS.REQUIRED:
          errorMessage = this.$i18n.t('valueRequired')
          break;
        case Constants.FORM_ERRORS.EMAIL:
          errorMessage = this.$i18n.t('notValidEmail')
          break;
        case Constants.FORM_ERRORS.SAME_AS:
          errorMessage = this.$i18n.t('valueMustBeEqual')
          break;
        case Constants.FORM_ERRORS.MAX_LENGTH:
          errorMessage = this.$i18n.t('valueTooLong')
          break;
        default:
          errorMessage = 'Error'
      }
      return errorMessage
    },
  },
}
</script>

<style lang="scss" scoped>
.error {
    color:darken(red,20%);
    text-align:left;
    font-size:12px;;
  }

</style>