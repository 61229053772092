<template>
    <h2 class="title-form">{{ $t('contactDetails') }}</h2>

    <section class="contWell">
      <div class="cont-field">
        <input class="form-control" type='text' 
          id='name' 
          :placeholder="$t('nameReq')"
          v-model='contact.name'
          @blur='onBlur' 
        />
        <InputErrors :errors="v$.contact.name.$errors"></InputErrors>
      </div>

      <div class="cont-field">
        <input class="form-control" type='text' 
          id='lastname' 
          :placeholder="$t('lastNameReq')"
          v-model='contact.lastname'
          @blur='onBlur'
        />
        <InputErrors :errors="v$.contact.lastname.$errors"></InputErrors>
      </div>

      <div class="cont-field">
        <input class="form-control" type='text' 
          id='organization' 
          :placeholder="$t('organization')"
          v-model='contact.organization'
          @blur='onBlur'
        />
      </div>
      <div class="cont-field">
        <input class="form-control" type='text' 
          id='address' 
          :placeholder="$t('address')"
          v-model='contact.address'
          @blur='onBlur'
        />
      </div>
      <div class="cont-field">
        <input class="form-control" type='text' 
          id='city' 
          :placeholder="$t('city')"
          v-model='contact.city'
          @blur='onBlur'
        />
      </div>
      <div class="cont-field">
        <input class="form-control" type='text' 
          id='postcode' 
          :placeholder="$t('postcode')"
          v-model='contact.postcode'
          @blur='onBlur'
        />
      </div>
      <div class="cont-field">
        <CountryInput
          v-model='contact.country'
          @blur='onBlur'
          @change='onChange'
          :label="$t('countryReq')"
          :language="language"
        />
        <InputErrors :errors="v$.contact.country.$errors"></InputErrors>
      </div>

      <div class="cont-field">
        <PrefixInput
          v-model='phoneValue'
          id='phone'
          @blurInput='onBlurPhone'
          @country-changed="countryChange($event)"
          ref='prefixInput'
          :defaultCountry="'BLANK'"
        />
        <InputErrors :errors="v$.phoneValue.$errors"></InputErrors>
         <!-- phoneValue needed to write a phone in the form, but not used to save data -->
      </div>

      <p class="clearboth">
          {{ $t('emailText') }}
      </p>

      <div class="cont-field">
        <input class="form-control" type='email' 
          id='email' 
          :placeholder="$t('emailAddress')"
          v-model='contact.email'
          @blur='onBlur'
        />
        <InputErrors :errors="v$.contact.email.$errors"></InputErrors>
      </div>

      <div class="cont-field">
        <input class="form-control" type='email' 
          id='email_verify' 
          :placeholder="$t('verifyEmail')"
          v-model='contact.email_verify'
          @blur='onBlur'
        />
        <InputErrors :errors="v$.contact.email_verify.$errors"></InputErrors>
      </div>
    </section>
</template>

<script>
import CountryInput from "@/components/CountryInput";
import PrefixInput from "./PrefixInput";
import InputErrors from "./InputErrors";
import useVuelidate from '@vuelidate/core'
import { required, email, sameAs, maxLength } from '@vuelidate/validators'

export default {
  components: {
    PrefixInput,
    CountryInput,
    InputErrors,
  },
  data() {
    return {
      v$: useVuelidate(),
      contact: {
        name: '',
        lastname: '',
        organization: '',
        address: '',
        city: '',
        postcode: '',
        country: '',
        phone_prefix: '',
        phone: '',
        email: '',
        email_verify: '',
      },
      phoneValue: '',
    }
  },
  props: {
    label: String,
    language: String,
  },
  methods: {
    onBlur(ev) {
      const id = ev.target.id
      this.v$.contact[id]?.$touch()
      this.$emit('blurInput', {data: this.contact, key: 'contact'})
    },
    onChange() {
      this.$refs.prefixInput.syncCountry(this.contact.country.iso)
    },
    onBlurPhone(ev) {
      this.contact.phone = ev.nationalNumber || this.phoneValue
      this.$emit('blurInput', {data: this.contact, key: 'contact'})
    },
    countryChange(country) {
      this.contact.phone_prefix = country.dialCode
      this.$emit('blurInput', {data: this.contact, key: 'contact'})
    },
    async checkValidate() {
      return await this.v$.$validate()
    },

  },
  validations() {
    return {
      contact: {
        name: {
            required,
            maxLength: maxLength(150)
        },
        lastname: {
            required,
            maxLength: maxLength(150)
        },
        organization: {
            maxLength: maxLength(150)
        },
        address: {
            maxLength: maxLength(150)
        },
        city: {
            maxLength: maxLength(150)
        },
        postcode: {
            maxLength: maxLength(150)
        },
        country: {
            required,
            maxLength: maxLength(150)
        },
        phone_prefix: {
            required,
            maxLength: maxLength(15)
        },
        phone: {
            required,
            maxLength: maxLength(30)
        },
        email: {
            required,
            email,
            maxLength: maxLength(254)
        },
        email_verify: {
            required,
            sameEmail: sameAs(this.contact.email),
        },
      },
      phoneValue: {
          required
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.title-form {
  font-family:"Frank Ruhl Libre", serif;
  font-size:34px;
  margin: 0 20px 20px 20px;
  color: black;
}
.contWell {
  width:50%;
  max-width:1170px;
  margin:0 auto 20px;
  padding:15px;
  background:lighten(grey,42%);
  overflow: visible;
  height: 390px;
}
.clearboth {
  display:block;
  width:100%;
  clear:both;
  font-size: 12px;
}
.cont-field {
  height:55px;
  width: 48%;
  margin:1%;
  float:left;
  .form-control {
    width:100%;
  }
}
.form-control {
  float:left;
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: $primary-black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid gray;
  border-radius: .25rem;
}

@media (max-width: 768px) {
  .contWell {
    width:100%;
    height:600px;
    .cont-field {
      width:100%;
      margin:1% 0 1% 0;
      height:45px;
    }
    .form-control {
      width:100%;
      float:none;
      margin:0;
    }
  }
}

</style>