<template>

    <h2 class="title-form">{{ $t('billingInformation') }}</h2>
    <p class="break-text">
      {{ $t('billingText') }}
    </p>
    <p class="break-text">
      {{ $t('billingText2') }}
    </p>
    <p class="break-text">
      {{ $t('billingText3') }}
    </p>
    <p class="break-text-last">
      {{ $t('billingText4') }}
    </p>
    <section class="contWell">
      <div class="cont-field">
        <input type='text' class="form-control"
          id='billing_organization' 
          :placeholder="$t('billingOrganization')" 
          v-model='billing.billing_organization'
          @blur='onBlur'
        />
        <InputErrors :errors="v$.billing.billing_organization.$errors"></InputErrors>
      </div>

      <div class="cont-field">
        <input type='text' class="form-control"
          id='billing_tax_number' 
          :placeholder="$t('billingTaxNumber')" 
          v-model='billing.billing_tax_number'
          @blur='onBlur' 
        />
        <InputErrors :errors="v$.billing.billing_tax_number.$errors"></InputErrors>
      </div>
      
      <div class="cont-field">
        <input type='text' class="form-control"
          id='billing_address' 
          :placeholder="$t('billingAddresss')" 
          v-model='billing.billing_address'
          @blur='onBlur' 
        />
        <InputErrors :errors="v$.billing.billing_address.$errors"></InputErrors>
      </div>

      <div class="cont-field">
        <input type='text' class="form-control"
          id='billing_city' 
          :placeholder="$t('billingCity')" 
          v-model='billing.billing_city'
          @blur='onBlur' 
        />
        <InputErrors :errors="v$.billing.billing_city.$errors"></InputErrors>
      </div>

      <div class="cont-field">
        <input type='text' class="form-control"
          id='billing_postcode' 
          :placeholder="$t('billingPostcode')" 
          v-model='billing.billing_postcode'
          @blur='onBlur' 
        />
        <InputErrors :errors="v$.billing.billing_postcode.$errors"></InputErrors>
      </div>

      <div class="cont-field">
        <CountryInput
          v-model='billing.billing_country'
          @blur='onBlur' 
          :label="$t('billingCountry')"
          :language="language"
        />
        <InputErrors :errors="v$.billing.billing_country.$errors"></InputErrors>
      </div>
    </section>
  
</template>

<script>
import CountryInput from "@/components/CountryInput";
import useVuelidate from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'
import InputErrors from "./InputErrors";

export default {
  components: {
    CountryInput,
    InputErrors,
  },
  data() {
    return {
      v$: useVuelidate(),
      billing: {
        billing_organization: '',
        billing_tax_number: '',
        billing_address: '',
        billing_city: '',
        billing_postcode: '',
        billing_country: '',
      },
    }
  },
  props: {
    label: String,
    language: String,
  },
  methods: {
    async onBlur(ev) {
      const id = ev.target.id
      this.v$.billing[id]?.$touch()
      this.$emit('blurInput', {data: this.billing, key: 'billing'})
    },
    async checkValidate() {
      return await this.v$.$validate()
    },
  },
  validations() {
    return {
      billing: {
        billing_organization: {
            required,
            maxLength: maxLength(150)
        },
        billing_tax_number: {
            required,
            maxLength: maxLength(150)
        },
        billing_address: {
            required,
            maxLength: maxLength(150)
        },
        billing_city: {
            required,
            maxLength: maxLength(150)
        },
       billing_postcode: {
            required,
           maxLength: maxLength(150)
        },
        billing_country: {
            required,
            maxLength: maxLength(150)
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.title-form {
  font-family:"Frank Ruhl Libre", serif;
  font-size:34px;
  margin: 0 20px 0px 20px;
  color: black;
}
.contWell {
  width:50%;
  max-width:1170px;
  margin:0 auto 20px;
  padding:15px;
  background:lighten(grey,42%);
  overflow:hidden;
}
.break-text {
  width: 50%;
  text-align:left;
  margin:0 auto;
  font-size: 12px;
}
.break-text-last {
  width: 50%;
  text-align:left;
  margin:0 auto 10px;
  font-size: 12px;
}
.clearboth {
  display:block;
  width:100%;
  clear:both;
}
.cont-field {
  height:55px;
  width: 48%;
  margin:1%;
  float:left;
  .form-control {
    width:100%;
  }
}
.form-control {
  float:left;
  display: block;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  margin:0 0 4px 0;
  font-size: 1rem;
  line-height: 1.5;
  color: $primary-black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid gray;
  border-radius: .25rem;
}

@media (max-width: 768px) {
  .contWell {
    width:100%;
    .cont-field {
      width:100%;
      margin:1% 0 1% 0;
      height:45px;
    }
    .form-control {
      width:100%;
      float:none;
      margin:0;
    }
  }
}

</style>